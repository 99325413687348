/* eslint-disable max-lines */
import { ExpandMore } from '@mui/icons-material';
import { createTheme, darken, alpha, lighten } from '@mui/material/styles';
import { createElement } from 'react';

import { palette, COLORS } from './palette';
import { typography, WEIGHT_SEMIBOLD } from './typography';

// TODO - these values should be imported from Table component
const ICON_CELL_CLASS = 'iconCell';
const EXPAND_CONTENT_ROW_CLASS = 'expandContentRow';
const EXPAND_CONTENT_CELL_CLASS = 'expandContentCell';

export const CommonInputStyles = {
  borderRadius: 2,
  minHeight: 32,
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.BLACK010}`,
  '&:not(.Mui-disabled):hover': {
    borderColor: COLORS.BLACK010,
    backgroundColor: COLORS.BLACK002,
  },
  '&:focus': {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.BLACK010,
  },
};

const InputErrorStyles = {
  borderColor: COLORS.RASPBERRY,
  backgroundColor: '#FFFBFC',
  '&:hover': {
    borderColor: COLORS.RASPBERRY,
  },
};

const InputDisableStyles = {
  borderRadius: 2,
  backgroundColor: COLORS.BLACK008,
  color: COLORS.TEXT_LIGHT,
};

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
      styleOverrides: {
        root: {
          minHeight: 44,
          background: palette.background.paper,
          borderBottom: `1px solid ${palette.divider}`,
        },
        flexContainer: {
          padding: '0 32px',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          ...typography.body1,
          fontWeight: 600,
          textTransform: 'none',
          padding: '8px 16px',
          margin: 0,
          minHeight: 43,
          minWidth: '60px !important',
          transition: 'background 0.3s ease-in-out',
          color: palette.text.primary,
          '&:hover': {
            background: COLORS.BLACK006,
          },
          '&.Mui-disabled': {
            color: palette.action.disabled,
          },
          '&.Mui-selected': {
            color: palette.text.primary,
          },
        },
        textColorInherit: {
          opacity: 1,
          '&.Mui-disabled': {
            opacity: '1 !important',
            color: COLORS.BLACK030,
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: createElement(ExpandMore),
      },
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          borderRadius: 2,
          padding: '6px 12px',
          '&.MuiButton-contained': {
            color: COLORS.WHITE,

            '&.Mui-disabled': {
              color: COLORS.BACKGROUND,
              backgroundColor: COLORS.BLACK030,
            },
          },
          '&.MuiButton-sizeSmall': {
            padding: '4px 8px',
            fontSize: 12,
          },
          '&.MuiButton-sizeLarge': {
            padding: '8px 16px',
            fontSize: 14,
            lineHeight: '22px',
          },
          '&.MuiButton-text&.MuiButton-sizeSmall': {
            padding: '4px',
            fontSize: 12,
          },
          '&.MuiButton-text': {
            padding: '6px',
            minWidth: 'auto',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: 32,
          width: 32,
          '& .MuiSvgIcon-root': {
            height: 18,
            width: 18,
          },
          '&.MuiAutocomplete-clearIndicator, &.MuiAutocomplete-popupIndicator': {
            height: 28,
            width: 28,
          },
        },
        sizeSmall: {
          height: 28,
          width: 28,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${COLORS.BLACK010}`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.Mui-selected:not(.${EXPAND_CONTENT_ROW_CLASS})`]: {
            '&:nth-of-type(4n), &:nth-of-type(4n + 1)': {
              [`&, & + .${EXPAND_CONTENT_ROW_CLASS}`]: {
                backgroundColor: COLORS.BLACK002,
              },
            },
            '&:nth-of-type(4n + 2), &:nth-of-type(4n + 3)': {
              [`&, & + .${EXPAND_CONTENT_ROW_CLASS}`]: {
                backgroundColor: COLORS.BLACK004,
              },
            },
          },
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: COLORS.REGAL008,
              borderColor: COLORS.BLACK010,
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...typography.body1,
          borderBottom: `1px solid ${COLORS.BLACK010}`,
          height: 44,
          wordBreak: 'break-all',
          padding: 0,
          paddingLeft: 10,
          paddingRight: 10,
          '&:first-of-type': {
            paddingLeft: 32,
            maxWidth: '60px !important',
          },
          [`&:last-child:not(.${ICON_CELL_CLASS}, .${EXPAND_CONTENT_CELL_CLASS})`]: {
            paddingRight: 32,
          },
          [`&:not(.${ICON_CELL_CLASS}, .${EXPAND_CONTENT_CELL_CLASS})`]: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '50px',
          },
        },
        head: {
          height: 50,
          fontWeight: 600,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: COLORS.WHITE,
        },
        li: {
          opacity: 0.8,
          '& > a': {
            color: COLORS.WHITE,
          },
          '&:last-child': {
            opacity: 1,
          },
        },
        separator: {
          opacity: 0.8,
          '&:nth-last-of-type(2)': {
            opacity: 1,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            ...InputErrorStyles,
            '& input, & textarea': {
              ...InputErrorStyles,
            },
          },
          '&.Mui-disabled': {
            ...InputDisableStyles,
            '& input, & textarea': {
              ...InputDisableStyles,
              WebkitTextFillColor: COLORS.TEXT_LIGHT,
            },
          },
        },
        multiline: {
          padding: 0,
          lineHeight: '30px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          ...typography.body1,
          ...CommonInputStyles,
          '&::-webkit-search-cancel-button': {
            display: 'none',
          },
          padding: '0 12px',
          boxSizing: 'border-box',
          color: COLORS.TEXT_DARK,
          '&.Mui-disabled': {
            padding: '2px 8px 2px 12px',
          },
          '&::placeholder': {
            color: COLORS.BLACK030,
          },
        },
        inputTypeSearch: {
          padding: '2px 32px 2px 12px',
        },
        inputMultiline: {
          lineHeight: '30px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          border: 'none',
          minHeight: 32,
        },
        inputRoot: {
          ...CommonInputStyles,
          paddingLeft: 12,
          '&.Mui-error': {
            ...InputErrorStyles,
          },
          '&.MuiInput-root': {
            paddingBottom: 0,
            '&.Mui-disabled': {
              ...InputDisableStyles,
              '& input, & textarea': {
                ...InputDisableStyles,
                WebkitTextFillColor: COLORS.TEXT_LIGHT,
              },

              '&:hover input, &:hover textarea': {
                ...InputDisableStyles,
              },
            },
            '&:hover input, &:hover textarea': {
              borderColor: COLORS.BLACK010,
              backgroundColor: COLORS.BLACK002,
            },
          },
        },
        tag: {
          ...typography.body2,
          height: 28,
          fontWeight: WEIGHT_SEMIBOLD,
          padding: '3px 2px 3px 12px',
          margin: '1px 4px 1px 0px',
          '& .MuiChip-deleteIcon': {
            width: 22,
            height: 22,
          },
        },
        endAdornment: {
          paddingRight: 8,
        },
        option: {
          height: 36,
          '&:hover': {
            backgroundColor: COLORS.BLACK004,
            '&[aria-selected=true]': {
              backgroundColor: COLORS.REGAL016,
            },
          },
          '&[aria-selected=true]': {
            backgroundColor: COLORS.REGAL008,
          },
        },
        popupIndicator: {
          '&:hover': {
            background: alpha(COLORS.TEXT_DARK, 0.04),
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...typography.caption,
          color: COLORS.TEXT_LIGHT,
          marginLeft: 12,
          '&.Mui-focused': {
            color: COLORS.TEXT_LIGHT,
          },
        },
        shrink: {
          transform: 'translate(0)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 5,
          '& svg': {
            width: 18,
            height: 18,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 5,
          '& svg': {
            width: 18,
            height: 18,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.caption,
          marginLeft: 12,
          marginTop: 2,
          '&.Mui-error': {
            '&:first-letter': {
              textTransform: 'uppercase',
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -5,
        },
        label: {
          fontSize: 13,
          lineHeight: '28px',
          marginLeft: 4,
          width: '100%',

          '&.Mui-disabled': {
            color: COLORS.TEXT_DARK,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginLeft: 12,
          fontSize: 12,
          lineHeight: '16px',
          color: COLORS.TEXT_LIGHT,
          '&.Mui-focused': {
            color: COLORS.TEXT_LIGHT,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...typography.body1,
          height: 32,
          minWidth: 32,
          fontWeight: WEIGHT_SEMIBOLD,
          padding: '2px 2px 2px 14px',
          margin: '0 8px 4px 0',
          backgroundColor: COLORS.TEXT_LIGHT,
          color: COLORS.WHITE,
          borderRadius: 50,
          '&:hover': {
            backgroundColor: lighten(COLORS.TEXT_DARK, 0.16),
          },
        },
        sizeSmall: {
          ...typography.body2,
          height: 28,
          fontWeight: WEIGHT_SEMIBOLD,
          padding: '3px 3px 3px 12px',
          margin: '0 4px 4px 0',
          minWidth: 28,
        },
        label: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        labelSmall: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        deleteIcon: {
          width: 28,
          height: 28,
          color: COLORS.BLACK030,
          margin: '0 0 0 4px',
          '&:hover': {
            backgroundColor: alpha(COLORS.WHITE, 0.08),
            borderRadius: 50,
            color: COLORS.BLACK030,
          },
        },
        deleteIconSmall: {
          width: 22,
          height: 22,
          color: COLORS.BLACK030,
          margin: '0 0 0 10px',
          '&:hover': {
            backgroundColor: alpha(COLORS.WHITE, 0.08),
            borderRadius: 50,
            color: COLORS.BLACK030,
          },
        },
        deletable: {
          '&:hover': {
            backgroundColor: lighten(COLORS.TEXT_DARK, 0.16),
          },
          '&:focus': {
            backgroundColor: COLORS.TEXT_LIGHT,
          },
        },
        clickable: {
          '&:hover': {
            backgroundColor: lighten(COLORS.TEXT_DARK, 0.16),
          },
          '&:focus': {
            backgroundColor: COLORS.TEXT_LIGHT,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '.PrivatePickersYear-yearButton': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.75,
          },
        },
        elevation8: {
          boxShadow: '0px 0px 4px rgba(15, 15, 15, 0.16)',
        },
        elevation0: {
          boxShadow: '0px 2px 4px rgba(15, 15, 15, 0.08)',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        },
        rounded: {
          borderRadius: 2,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {
          ...typography.caption,
          borderRadius: 2,
          padding: '6px 8px',
          color: COLORS.WHITE,
          backgroundColor: COLORS.REGAL,
          margin: '0 !important',
        },
        tooltipPlacementLeft: {
          marginRight: '8px !important',
        },
        tooltipPlacementRight: {
          marginLeft: '8px !important',
        },
        tooltipPlacementTop: {
          marginBottom: '8px !important',
        },
        tooltipPlacementBottom: {
          marginTop: '8px !important',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        scrollPaper: {
          backgroundColor: 'rgba(15, 15, 15, 0.15);',
        },
        paper: {
          borderRadius: 2,
          width: '100%',
          boxShadow: '0px 0px 4px rgba(15, 15, 15, 0.16)',
        },
        paperWidthSm: {
          maxWidth: '672px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          fontWeight: WEIGHT_SEMIBOLD,
          fontSize: 16,
          lineHeight: '26px',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          marginBottom: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            paddingTop: '24px',
          },
        },
        dividers: {
          padding: '30px 16px 0 16px',
          borderBottom: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: '12px 24px',
          minWidth: '33.3%',
          maxWidth: '66.6%',
          '& ul': {
            marginLeft: 50,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: COLORS.AQUA,
          background: 'none',
          lineHeight: '16px',
          fontSize: '10px',
          padding: '0px 4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: `${COLORS.REGAL}`,
            color: `${COLORS.WHITE}`,
          },
          '&.danger': {
            color: COLORS.RASPBERRY,
            '&:hover': {
              color: COLORS.WHITE,
              backgroundColor: COLORS.RASPBERRY,
            },
          },
          '&.Mui-selected': {
            backgroundColor: COLORS.BLACK010,
            '&:hover': {
              backgroundColor: `${COLORS.REGAL}`,
              color: COLORS.WHITE,
            },
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: COLORS.TEXT_DARK,
        },
        message: {
          ...typography.body1,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          boxShadow: 'none',
          padding: '12px 16px',
          ...typography.body1,
          '&.MuiAlert-standardWarning': {
            backgroundColor: COLORS.HONEY008,

            '& .MuiAlert-icon': {
              color: COLORS.HONEY,
            },
          },
          '&.MuiAlert-standardSuccess': {
            backgroundColor: COLORS.MENTHOL008,

            '& .MuiAlert-icon': {
              color: COLORS.MENTHOL,
            },
          },
          '&.MuiAlert-standardInfo': {
            backgroundColor: COLORS.REGAL008,

            '& .MuiAlert-icon': {
              color: COLORS.REGAL,
            },
          },
          '&.MuiAlert-standardError': {
            backgroundColor: COLORS.RASPBERRY008,

            '& .MuiAlert-icon': {
              color: COLORS.RASPBERRY,
            },
          },
        },
        icon: {
          padding: '3px 0',
        },
        message: {
          color: COLORS.TEXT_DARK,
          padding: 0,
        },
        action: {
          marginRight: 0,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
          fontWeight: 600,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 18,
          padding: 0,
          margin: 8,
          overflow: 'visible',
        },
        switchBase: {
          padding: 5,
          marginTop: -5,
          marginLeft: -5,
          width: 'auto',
          height: 'auto',
          '&.Mui-disabled': {
            color: `${COLORS.BACKGROUND}!important`,
            '& + .MuiSwitch-track': {
              backgroundColor: `${COLORS.BLACK030}!important`,
              opacity: 1,
            },
          },
          '&.Mui-checked': {
            transform: 'translateX(10px)',
            color: `${COLORS.WHITE}!important`,
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },
        },
        thumb: {
          width: 18,
          height: 18,
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.4)',
        },
        track: {
          borderRadius: 18 / 2,
          backgroundColor: palette.action.disabled,
          opacity: 1,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          height: 30,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          fontSize: 13,
          lineHeight: '20px',
          fontWeight: 600,
          border: 'none',
          color: palette.text.secondary,
          backgroundColor: palette.background.grayLight,
          padding: '6px 12px',
          '&:hover': {
            border: 'none',
            backgroundColor: darken(palette.background.grayLight, 0.16),
          },
          '&.Mui-selected': {
            backgroundColor: COLORS.REGAL,
            color: COLORS.WHITE,
            '&:hover': {
              backgroundColor: COLORS.REGAL,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: COLORS.BLACK010,
            color: COLORS.BLACK030,
            '&.Mui-selected': {
              backgroundColor: COLORS.BLACK030,
              color: COLORS.BLACK008,
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          height: 4,
          opacity: 0.15,
        },
        mark: {
          height: 4,
          width: 4,
          borderRadius: 2,
        },
        thumb: {
          marginTop: -4,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0)',
          width: '100%',
          padding: 0,
          border: 'none',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '& .MuiStepConnector-line': {
              opacity: 1,
            },
          },
          '&.Mui-completed': {
            '& .MuiStepConnector-line': {
              opacity: 1,
            },
          },
        },
        line: {
          borderColor: COLORS.WHITE,
          opacity: 0.4,
          borderTopWidth: 1,
          borderRadius: 1,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        horizontal: {
          paddingLeft: 12,
          paddingRight: 12,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          ...typography.body1,
          lineHeight: '18px',
          color: 'rgba(255, 255, 255, 0.8)',

          '&.Mui-active': {
            color: COLORS.WHITE + ' !important',
          },
          '&.Mui-completed': {
            color: COLORS.WHITE + ' !important',
          },
        },
        labelContainer: {
          color: COLORS.WHITE,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        iconContainer: {
          paddingRight: 12,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(255, 255, 255, 0.4)',
          zIndex: 1,
          color: 'transparent',
          width: 32,
          height: 32,
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'none',
          '&.Mui-active': {
            color: COLORS.WHITE,
            border: 'none',
            '& text': {
              fill: COLORS.REGAL,
            },
          },
          '&.Mui-completed': {
            color: COLORS.WHITE,
            border: 'none',
            '& text': {
              fill: COLORS.REGAL,
            },
          },
        },
        text: {
          fontSize: 10,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '20px',
          display: 'block',
          fontSize: typography.body1.fontSize,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...CommonInputStyles,

          '& input': {
            lineHeight: '18px',
            height: '30px',
          },
        },
        input: {
          boxSizing: 'border-box',
        },
        notchedOutline: {
          border: 'none',
        },
        adornedEnd: {
          paddingRight: 8,
        },
        adornedStart: {
          paddingLeft: 12,
        },
      },
    },
  },
});
