import { styled } from '@mui/material/styles';

export const StyledSnackbarWrapperDiv = styled('div')`
  .SnackbarContainer-root {
    bottom: 32px !important;
    gap: 12px;

    & > .MuiCollapse-root > .MuiCollapse-wrapper {
      padding: 0 !important;
    }
  }

  .SnackbarItem-contentRoot {
    box-shadow: 0px 0px 4px rgba(15, 15, 15, 0.16) !important;
    padding: 12px 16px !important;
  }

  & .SnackbarItem-message {
    font-size: 13px;
    line-height: 28px;
    max-width: 570px;
    padding: 0 !important;

    & svg {
      font-size: 18px;
      height: 28px;
      margin-inline-end: 11px;
    }
  }

  & .SnackbarItem-variantError {
    background-color: ${({ theme }) => theme.palette.error.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.error.main};
    }
  }

  & .SnackbarItem-variantInfo {
    background-color: ${({ theme }) => theme.palette.info.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.info.main};
    }
  }

  & .SnackbarItem-variantSuccess {
    background-color: ${({ theme }) => theme.palette.success.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.success.main};
    }
  }

  & .SnackbarItem-variantWarning {
    background-color: ${({ theme }) => theme.palette.warning.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.warning.main};
    }
  }
`;
