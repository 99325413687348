import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

export const REQUIRED_SCOPES = new Set(['openid']);
export const SCOPES_FIELD = 'scopes';

export interface ApplicationFormProps {
  appName: string;
  clientId: string;
  developerEmail: string;
}

export function ApplicationInfo({
  appName,
  clientId,
  developerEmail,
}: ApplicationFormProps) {
  return (
    <>
      <Typography variant="h3" gutterBottom data-test="authorize-app-text">
        Authorize application
      </Typography>
      <TableContainer>
        <Table
          size="small"
          padding="checkbox"
          sx={{ marginBottom: 2, tableLayout: 'fixed' }}
        >
          <TableBody>
            <TableRow data-test="application-name-row">
              <TableCell component="th" scope="row">
                Application name
              </TableCell>
              <TableCell>
                {appName}
                <Tooltip
                  title={`Client id: ${clientId}`}
                  placement="right"
                  sx={{ marginLeft: 1 }}
                >
                  <InfoIcon />
                </Tooltip>
              </TableCell>
            </TableRow>
            <TableRow data-test="developer-email-row">
              <TableCell component="th" scope="row">
                Developer email
              </TableCell>
              <TableCell>{developerEmail}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
