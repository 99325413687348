import { Button as MuiButton, IconButton as MuiIconButton, CircularProgress, Tooltip } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const StyledButton = styled(MuiButton, { shouldForwardProp: prop => prop !== 'loading' })<{ loading?: boolean }>`
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
`;

const StyledIconButton = styled(MuiIconButton, { shouldForwardProp: prop => prop !== 'loading' })<{
  loading?: boolean;
}>`
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
`;

const StyledProgressLayerContainerDiv = styled('div')`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledHiddenLabelSpan = styled('span')`
  visibility: hidden;
`;

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: MuiButtonProps['color'] | 'default';
  icon?: boolean;
  loading?: boolean;
  allowed?: boolean;
  notAllowedMessage?: string;
  'data-test'?: string;
}

export function Button({
  variant = 'contained',
  size = 'medium',
  color = 'default',
  loading = false,
  disabled = false,
  allowed = true,
  icon = false,
  'data-test': dataTest = 'button',
  notAllowedMessage = 'You need permission to perform this action',
  children,
  onClick,
  href,
  ...restProps
}: ButtonProps): JSX.Element {
  const circularProgress = useMemo(() => {
    const CircularProgressColor: Record<NonNullable<ButtonProps['variant']>, string> = {
      contained: 'inherit',
      outlined: color,
      text: color,
    };

    const CircularProgressColorSize: Record<NonNullable<MuiButtonProps['size']>, number> = {
      large: 18,
      medium: 16,
      small: 14,
    };

    return (
      <StyledProgressLayerContainerDiv>
        <CircularProgress
          color={CircularProgressColor[variant] as MuiButtonProps['color']}
          size={CircularProgressColorSize[size]}
        />
      </StyledProgressLayerContainerDiv>
    );
  }, [color, variant, size]);

  const label = useMemo(() => {
    if (loading) {
      return <StyledHiddenLabelSpan>{children}</StyledHiddenLabelSpan>;
    }
    return children;
  }, [loading, children]);

  const ButtonComponent = (icon ? StyledIconButton : StyledButton) as React.ElementType;

  if (!allowed) {
    return (
      <Tooltip title={notAllowedMessage}>
        <span>
          <ButtonComponent variant={variant} size={size} data-test={dataTest} {...restProps} disabled>
            {children}
          </ButtonComponent>
        </span>
      </Tooltip>
    );
  }

  if (href) {
    return (
      <Link to={href}>
        <ButtonComponent
          variant={variant}
          color={color}
          size={size}
          disabled={disabled}
          loading={loading}
          disableRipple={variant === 'text'}
          data-test={dataTest}
          {...restProps}
        >
          {label}
          {loading && circularProgress}
        </ButtonComponent>
      </Link>
    );
  }

  return (
    <ButtonComponent
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      loading={loading}
      disableRipple={variant === 'text'}
      data-test={dataTest}
      onClick={onClick}
      {...restProps}
    >
      {label}
      {loading && circularProgress}
    </ButtonComponent>
  );
}
