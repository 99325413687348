import { Alert, AlertTitle } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledErrorListUl = styled('ul')<{ hasSingleError: boolean }>`
  margin-left: ${({ theme }) => theme.spacing(2.5)};

  ${({ hasSingleError }) =>
    hasSingleError &&
    css`
      list-style-type: none;
      margin-left: 0;
    `}
`;

interface FormErrorsProps {
  title?: string;
  errors: (string | undefined)[];
}

export function FormErrors(props: FormErrorsProps): JSX.Element {
  const { t } = useTranslation();
  const { errors, title } = props;

  if (errors.filter(e => e).length === 0) {
    return <></>;
  }

  const singleError = errors.length === 1;
  const alertTitle = title || t('Please correct any issues in the form and try again.');

  return (
    <Alert severity="error" data-test="error-message">
      <AlertTitle>{alertTitle}</AlertTitle>
      <StyledErrorListUl hasSingleError={singleError}>
        {errors.map((error, index) => error && <li key={index}>{error}</li>)}
      </StyledErrorListUl>
    </Alert>
  );
}
