import { styled, css } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledContainerDiv = styled('div')<{ isFullPage: boolean }>`
  ${({ isFullPage }) =>
    isFullPage
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: calc(100vh - var(--content-top-offset));
        `
      : css`
          min-height: 560px;
        `}
`;

interface FormContainerProps {
  children?: ReactNode | ReactNode[];
  fullPage?: boolean;
}

export const FormContainer = ({ children, fullPage = false }: FormContainerProps): JSX.Element => {
  return <StyledContainerDiv isFullPage={fullPage}>{children}</StyledContainerDiv>;
};
