import {
  forwardRef,
  useContext,
  createContext,
  useRef,
  Children,
  cloneElement,
  useEffect,
  ComponentType,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

import { AutoComplete } from './AutoComplete';
import { AutocompleteProps } from './types';

type ListboxComponentProps = PropsWithChildren & {
  width?: string;
  itemSize?: number;
};

const LISTBOX_PADDING = 8;
const ITEM_SIZE = 36;

function renderRow(props: ListChildComponentProps): JSX.Element {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: unknown) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

// Adapter for react-window
export const ListboxComponent = forwardRef<HTMLDivElement, ListboxComponentProps>((props, ref) => {
  const { children, width, itemSize = ITEM_SIZE, ...other } = props;
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;

  const getChildSize = (): number => itemSize;

  const getHeight = (): number => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref} data-test="list-of-items">
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width={width || '100%'}
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => getChildSize()}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export function AutoCompleteVirtualize<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  return (
    <AutoComplete
      disableListWrap
      ListboxComponent={ListboxComponent as ComponentType<HTMLAttributes<HTMLElement>>}
      {...props}
    />
  );
}
