export interface PhraseChunk {
  content: string;
  highlighted: boolean;
}

export type HighlightPhraseProps = string | string[];

function escapeRegExp(phrase: string): string {
  return phrase.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const REGEXP_OR_SYMBOL = '|';

export function prepareHighlightRegExp(highlightPhrase: HighlightPhraseProps): RegExp {
  const phrases = Array.isArray(highlightPhrase) ? highlightPhrase : [highlightPhrase];
  const normalizedPhrases = phrases.map(escapeRegExp).sort((a, b) => b.length - a.length);

  return new RegExp(normalizedPhrases.join(REGEXP_OR_SYMBOL), 'gi');
}

export function prepareHighlightedPhrase(wholePhrase: string, highlighterPhrase?: HighlightPhraseProps): PhraseChunk[] {
  if (!highlighterPhrase) {
    return [{ content: wholePhrase, highlighted: false }];
  }

  const regexp = prepareHighlightRegExp(highlighterPhrase);
  const parts: string[] = wholePhrase.split(regexp);
  const matches: string[] = wholePhrase.match(regexp) || [];

  const result: PhraseChunk[] = [];
  function addPhraseChunk(content: string, highlighted: boolean): void {
    result.push({ content, highlighted });
  }

  const firstChunk = parts.shift();

  if (firstChunk) {
    addPhraseChunk(firstChunk, false);
  }

  while (matches.length && parts.length) {
    const matchedChunk = matches.shift();
    const unmatchedChunk = parts.shift();

    if (matchedChunk) {
      addPhraseChunk(matchedChunk, true);
    }

    if (unmatchedChunk) {
      addPhraseChunk(unmatchedChunk, false);
    }
  }

  return result;
}
