import { Grid, Typography } from '@mui/material';
import { parse } from 'query-string';
import { FormEventHandler, MouseEventHandler, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { object, string } from 'yup';

import { Button, RHFTextField, FormContainer } from '@openx/components/core';
import { Shape } from '@openx/types';

export const EMAIL_FIELD = 'login';
export const PASSWORD_FIELD = 'password';

export interface LoginFormProps {
  onSubmitHandler: FormEventHandler<HTMLFormElement>;
  onForgotPassword: MouseEventHandler<HTMLElement>;
}

export interface FormValues {
  [EMAIL_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

export const validationSchema = object<Shape<FormValues>>().shape({
  [EMAIL_FIELD]: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  [PASSWORD_FIELD]: string().required('Password is required'),
});

export const LoginForm = ({
  onSubmitHandler,
  onForgotPassword,
}: LoginFormProps) => {
  const {
    setValue,
    formState: { isSubmitting },
  } = useFormContext<FormValues>();

  useEffect(() => {
    const { login_hint, email } = parse(window.location.search);

    if (typeof login_hint === 'string') {
      setValue(EMAIL_FIELD, login_hint);
      return;
    }
    if (typeof email === 'string') {
      setValue(EMAIL_FIELD, email);
      return;
    }
  }, [setValue]);

  return (
    <>
      <Typography variant="h2" gutterBottom data-test="login-text">
        Log in
      </Typography>
      <form onSubmit={onSubmitHandler} data-test="login-form">
        <FormContainer fullPage>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFTextField
                name={EMAIL_FIELD}
                type="email"
                label="Email"
                placeholder="Enter your email"
                required
                fullWidth
                margin="dense"
                autoComplete="username"
                data-test="email"
              />
              <RHFTextField
                name={PASSWORD_FIELD}
                type="password"
                label="Password"
                placeholder="Enter your password"
                required
                fullWidth
                margin="dense"
                autoComplete="current-password"
                data-test="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                direction="row"
              >
                <Grid item>
                  <Button
                    variant="text"
                    onClick={onForgotPassword}
                    data-test="forgot-password"
                  >
                    Forgot Password
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    loading={isSubmitting}
                    data-test="sign-in-button"
                    disabled={isSubmitting}
                  >
                    Sign in
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      </form>
    </>
  );
};
