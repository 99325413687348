import { Tooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useEffect, useRef, useState } from 'react';

const StyledEllipsisWrapperDiv = styled('div')<{ width: string }>`
  max-width: ${({ width }) => width};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface EllipsisProps {
  message?: string;
  children?: string | ReactNode;
  width: string;
  tooltip?: boolean;
  detectTooltipResize?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
}

const shouldShowTooltip = (wrapperNode: HTMLDivElement): boolean => {
  return wrapperNode && wrapperNode.offsetWidth < (wrapperNode.firstChild as HTMLDivElement)?.offsetWidth;
};

export const Ellipsis = ({
  message,
  children,
  tooltip = false,
  detectTooltipResize = false,
  tooltipPlacement,
  width,
  ...props
}: EllipsisProps): JSX.Element => {
  const text = message || children;
  const [showTooltip, setShowTooltip] = useState(tooltip);
  const ellipsisTextWrapperNode = useRef<HTMLDivElement>(null);

  const newResizeObserver = useRef<ResizeObserver | null>(
    detectTooltipResize
      ? new ResizeObserver(entries => {
          setShowTooltip(shouldShowTooltip(entries[0].target as HTMLDivElement));
        })
      : null,
  );

  useEffect(() => {
    const resizeObserverInstance = newResizeObserver.current;
    const currentNode = ellipsisTextWrapperNode.current;

    if (detectTooltipResize && resizeObserverInstance && currentNode) {
      resizeObserverInstance.observe(currentNode);
    }

    return () => {
      if (detectTooltipResize && resizeObserverInstance && currentNode) resizeObserverInstance.unobserve(currentNode);
    };
  }, [detectTooltipResize, newResizeObserver]);

  if (tooltip) {
    return (
      <Tooltip
        ref={ellipsisTextWrapperNode}
        title={showTooltip ? (text as string) : ''}
        data-test={props['data-test']}
        placement={tooltipPlacement}
      >
        <StyledEllipsisWrapperDiv width={width}>
          <span>{text}</span>
        </StyledEllipsisWrapperDiv>
      </Tooltip>
    );
  }

  return <StyledEllipsisWrapperDiv width={width}>{text}</StyledEllipsisWrapperDiv>;
};
