import { Grid, Typography, Box, SxProps, Theme } from '@mui/material';

import { Logo, OutsideLink } from '@openx/components/core';

const styles: { [key: string]: SxProps<Theme> } = {
  centeredContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  copyright: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 50px',
  },
  links: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  promoContainer: {
    background: 'linear-gradient(90.34deg, #0076BD 16.27%, #1FBAB0 74.56%)',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 50px',
  },
  rootContainer: {
    backgroundColor: theme => theme.palette.background.paper,
    height: '100vh',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '26px',
  },
  title: {
    fontSize: 36,
    fontWeight: 800,
    lineHeight: '22px',
  },
};

const EXTERNAL_LINKS: { title: string; link: string; dataTest: string }[] = [
  {
    dataTest: 'terms-and-conditions',
    link: 'https://www.openx.com/legal/terms-and-conditions',
    title: 'Terms and Conditions',
  },
  {
    dataTest: 'privacy',
    link: 'https://www.openx.com/legal/privacypolicy',
    title: 'Privacy Policy',
  },
  {
    dataTest: 'about',
    link: 'https://www.openx.com/about',
    title: 'About',
  },
  {
    dataTest: 'help',
    link: 'https://www.openx.com/support',
    title: 'Help',
  },
];

export function SsoLayout({ children }): JSX.Element {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      sx={styles.rootContainer}
    >
      <Grid item xs={4} sx={styles.formContainer}>
        <Box sx={{ width: 80 }}>
          <Logo darkMode data-test="logo" />
        </Box>
        <Box sx={styles.centeredContainer}>{children}</Box>
        <Box sx={styles.links}>
          {EXTERNAL_LINKS.map(({ title, link, dataTest }, index) => (
            <Box key={index} sx={{ marginX: '10px' }}>
              <OutsideLink to={link} openInNewTab={true} data-test={dataTest}>
                {title}
              </OutsideLink>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={8} sx={styles.promoContainer}>
        <Box sx={styles.centeredContainer}>
          <Typography
            variant="h1"
            gutterBottom
            sx={styles.title}
            fontSize={36}
            data-test="advert-text"
          >
            Reach your target audience
          </Typography>
          <Typography
            variant="h2"
            sx={styles.subtitle}
            data-test="advert-subtext"
          >
            Welcome to OpenX, where you can manage media buys to reach your
            target audience and maximize your ROI
          </Typography>
        </Box>
        <Box sx={styles.copyright}>
          <div>
            Copyright © {new Date().getFullYear()} OpenX. All rights reserved.
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
