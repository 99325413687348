import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, ComponentType, ReactNode } from 'react';

import { Button, ButtonProps } from '../Button';

const StyledErrorContainerDiv = styled('div')`
  display: flex;
  height: 100vh;
  width: 100%;
`;

const StyledInfoContainerDiv = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-left: 10%;
`;

const StyledTitleDiv = styled('div')`
  font-size: 48px;
  font-weight: 800;
  line-height: 64px;
  margin-bottom: 10px;
`;

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledActionSectionDiv = styled('div')`
  margin: ${({ theme }) => theme.spacing(3.75, 0)};

  & > button {
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  }
`;

const StyledPictureContainerDiv = styled('div')`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;

  & > svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

export type ErrorProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  actions?: ButtonProps[];
  PictureComponent?: ComponentType;
  className?: string;
  'data-test'?: string;
  titlePrefix?: string;
};

export const Error = (props: ErrorProps): JSX.Element => {
  const { title, titlePrefix, subtitle, actions, PictureComponent, className } = props;

  useEffect(() => {
    document.title = `${titlePrefix || 'Unity'} - ${title}`;
  }, [title, titlePrefix]);

  return (
    <StyledErrorContainerDiv className={className} data-test={props['data-test']}>
      <StyledInfoContainerDiv>
        <StyledTitleDiv>{title}</StyledTitleDiv>
        <StyledTypography variant="body1">{subtitle}</StyledTypography>
        <StyledActionSectionDiv>
          {actions?.map((buttonProps, index) => (
            <Button key={index} {...buttonProps} />
          ))}
        </StyledActionSectionDiv>
      </StyledInfoContainerDiv>
      <StyledPictureContainerDiv>{PictureComponent && <PictureComponent />}</StyledPictureContainerDiv>
    </StyledErrorContainerDiv>
  );
};
