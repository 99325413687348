import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Divider, Grid, GridSize, IconButton, Typography, Box, Tooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledFormSectionWrapperDiv = styled('div')`
  width: 100%;
`;

const StyledFormSectionContainerGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledTitleTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const StyledDescriptionTypography = styled(Typography)`
  word-break: break-word;
` as typeof Typography;

const StyledTooltip = styled(Tooltip)`
  margin-left: 10px;
`;

const StyledHistoryModeTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  padding-top: ${({ theme }) => theme.spacing(1)};

  &:first-of-type {
    padding-top: 0;
  }
`;

interface FormSectionProps {
  children?: ReactNode;
  divider?: boolean;
  historyMode?: boolean;
  title: string;
  description?: string | ReactNode;
  leftColumnSize?: GridSize;
  rightColumnSize?: GridSize;
  customGrid?: boolean;
  info?: string | ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  ['data-test']?: string;
}

export function FormSection(props: FormSectionProps): JSX.Element {
  const {
    children,
    divider,
    title,
    description,
    info,
    customGrid,
    leftColumnSize = 3,
    rightColumnSize = 9,
    tooltipPlacement = 'top',
    historyMode = false,
  } = props;
  const dataTest = props['data-test'] || title.toLowerCase().replace(/\s/g, '-');

  if (historyMode) {
    return (
      <>
        <StyledHistoryModeTypography variant="h3" data-test="history-mode-section-title">
          {title}
        </StyledHistoryModeTypography>
        {children}
      </>
    );
  }

  return (
    <StyledFormSectionWrapperDiv data-test={dataTest}>
      <StyledFormSectionContainerGrid container>
        <Grid item xs={leftColumnSize}>
          <Box pr={3}>
            <StyledTitleTypography data-test="title" variant="h2">
              <Box component={'span'} display={'flex'}>
                {title}
                {info && (
                  <StyledTooltip placement={tooltipPlacement} title={<>{info}</>}>
                    <IconButton size="small">
                      <InfoRoundedIcon />
                    </IconButton>
                  </StyledTooltip>
                )}
              </Box>
            </StyledTitleTypography>
            {description && (
              <StyledDescriptionTypography
                variant="body1"
                data-test="description"
                color="textSecondary"
                component="div"
              >
                {description}
              </StyledDescriptionTypography>
            )}
          </Box>
        </Grid>
        {!customGrid ? (
          <Grid item xs={rightColumnSize}>
            {children}
          </Grid>
        ) : (
          children
        )}
      </StyledFormSectionContainerGrid>
      {divider && <Divider />}
    </StyledFormSectionWrapperDiv>
  );
}
