import { styled } from '@mui/material/styles';

import { Loader } from './Loader';

const StyledLoaderContainer = styled('div')`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const FullLoader = () => {
  return (
    <StyledLoaderContainer>
      <Loader />
    </StyledLoaderContainer>
  );
};
