import { QueryClientConfig } from '@tanstack/react-query';

/**
 * @description `gcTime` - The time in milliseconds that unused/inactive cache data remains in memory.
 * When a query's cache becomes unused or inactive, that cache data will be garbage collected after this duration.
 * Query results that have no more active instances of `useQuery`,
 * `useInfiniteQuery` or query observers are labeled as `"inactive"`.
 *
 * @link https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
 */
export const ReactQueryCacheTime: Record<string, number> = {
  MINUTES_15: 60 * 1000 * 15,
  MINUTES_90: 60 * 1000 * 90,
} as const;

/**
 * @description `staleTime` - The time in milliseconds after data is considered stale.
 * Stale queries are refetched automatically in the background when:
 * * New instances of the query mount
 * * The window is refocused (disabled in `ui-unity`)
 * * The network is reconnected
 * * The query is optionally configured with a refetch interval
 *
 * @link https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
 */
export const ReactQueryStaleTime: Record<string, number> = {
  MINUTES_15: 60 * 1000 * 15,
  MINUTES_60: 60 * 1000 * 60,
} as const;

export const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};
