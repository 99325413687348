export const paddingMap = {
  large: 40,
  medium: 20,
  none: 0,
  small: 10,
};

export const defaultPadding = 'medium';

export const verticalPaddingFactor = 1.66;
