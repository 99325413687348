import { Theme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const handleBackgroundType = (color: string, theme: Theme) => {
  switch (color) {
    case 'active':
      return theme.palette.success.main;
    case 'banned':
      return theme.palette.error.main;
    case 'error':
      return theme.palette.error.main;
    case 'goalreached':
      return theme.palette.primary.main;
    case 'inactive':
      return theme.palette.action.inactive;
    case 'warning':
      return theme.palette.warning.main;
  }
};

const StyledSpan = styled('span')<{ color: string }>`
  border-radius: 100%;
  display: inline-block;
  height: 8px;
  margin-right: 8px;
  width: 8px;

  background-color: ${({ color, theme }) => handleBackgroundType(color, theme)};
`;

interface StatusIndicatorProps {
  /** Status: 'active' | 'inactive' | 'error' | 'warning' | goalreached */
  status?: string;
  label?: string;
  className?: string;
  'data-test'?: string;
}

export function StatusIndicator(props: StatusIndicatorProps): JSX.Element {
  const { status = 'inactive', className, label } = props;
  const statusClass = status.toLowerCase().replace(' ', '');

  return (
    <span className={className} data-test={props['data-test']}>
      <StyledSpan data-test={`status-${status}`} color={statusClass} />
      <Typography variant="overline" color="textSecondary">
        {label?.toUpperCase() || status.toUpperCase()}
      </Typography>
    </span>
  );
}
