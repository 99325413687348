import { getEnvironmentConfigs } from '../../../environmentConfigs';

import { getApigeeUrl } from './getUrl';
import type {
  FetchConsentResponseParams,
  OnAcceptConsentResponse,
  OnDenyConsentResponse,
} from './types';

export const fetchConsentResponse = async ({
  currentUser,
  formParams,
}: FetchConsentResponseParams): Promise<
  OnAcceptConsentResponse | OnDenyConsentResponse
> => {
  const { apigee } = getEnvironmentConfigs();

  const idToken = await currentUser.getIdToken();

  const form = new URLSearchParams({
    consent: String(formParams.consent),
    session_id: formParams.sessionId,
  });

  if (formParams.consent) {
    if (formParams.instanceUID) {
      form.append('instance_uid', formParams.instanceUID);
    }
    if (formParams.scope) {
      form.append('scope', formParams.scope);
    }
  }

  const url = getApigeeUrl(apigee.consentPath);
  const response = await fetch(url, {
    body: form,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    method: 'POST',
    redirect: 'error',
  });

  const { status, statusText } = response;

  try {
    const result: OnAcceptConsentResponse | OnDenyConsentResponse =
      await response.json();
    return result;
  } catch (err) {
    throw new Error(`Can't parse response ${status}:${statusText}`);
  }
};
