import { getEnvironmentConfigs } from 'environmentConfigs';
import type { useLocation } from 'react-router-dom';

import type { OnAcceptConsentResponse, OnDenyConsentResponse } from './types';

const SESSION_ID_PARAM = 'session_id';
const STATE_PARAM = 'state';
const FORCE_LOGIN = 'force_login';
const INSTANCE_UID = 'instance_uid';

export interface OAuthParams {
  sessionId: string;
  state: string | null;
}

export function getOAuthParams(
  location: ReturnType<typeof useLocation>,
): OAuthParams {
  const sp = new URLSearchParams(location.search);
  return {
    sessionId: sp.get(SESSION_ID_PARAM) as string,
    state: sp.get(STATE_PARAM),
  };
}

export function getOAuthRedirectURL(
  response: OnAcceptConsentResponse | OnDenyConsentResponse,
) {
  if (!response.redirect_uri) {
    throw new Error("Response doesn't have `redirect_uri` parameter");
  }
  if (!response.state) {
    throw new Error("Response doesn't have `state` parameter");
  }
  const redirectURL = new URL(response.redirect_uri);

  redirectURL.searchParams.set('state', response.state);

  if ('error' in response) {
    redirectURL.searchParams.set('error', response.error);
    if (response.error_description) {
      redirectURL.searchParams.set(
        'error_description',
        response.error_description,
      );
    }
    return redirectURL.toString();
  }

  redirectURL.searchParams.set('code', response.code);
  return redirectURL.toString();
}

export function getOAuthRedirectURLInvalidQuery() {
  const config = getEnvironmentConfigs();
  const redirectURL = new URL(config.defaultRedirectURI);
  redirectURL.searchParams.set('error', 'invalid_request');
  redirectURL.searchParams.set('error_description', 'Malformed OAuth query');
  return redirectURL.toString();
}

export function isOAuthQueryValid(location: ReturnType<typeof useLocation>) {
  const sp = new URLSearchParams(location.search);

  return [SESSION_ID_PARAM]
    .map(paramName => {
      const params = sp.getAll(paramName);
      if (params.length !== 1) {
        return false;
      }
      const [param] = params;
      if (param.length === 0) {
        return false;
      }
      return true;
    })
    .every(Boolean);
}

export function setSearchParam(
  location: ReturnType<typeof useLocation>,
  value: {
    forceLogin: boolean;
    instanceUID?: string;
  },
) {
  const sp = new URLSearchParams(location.search);

  if (value.forceLogin) {
    sp.set(FORCE_LOGIN, 'true');
  } else {
    sp.delete(FORCE_LOGIN);
  }

  if (value.instanceUID) {
    sp.set(INSTANCE_UID, value.instanceUID);
  } else {
    sp.delete(INSTANCE_UID);
  }

  return `?${sp.toString()}`;
}

export function hasForceLogin(location: ReturnType<typeof useLocation>) {
  const sp = new URLSearchParams(location.search);
  return sp.has(FORCE_LOGIN);
}
