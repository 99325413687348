import { getEnvironmentConfigs } from 'environmentConfigs';

/**
 * for running app locally we need to proxying apigee,
 * so if apigee.url is empty we are in development env and we need to use localhost (location.origin)
 * as a base url. In devint or qa there is a config file with filled apigee.url property
 **/
export const getApigeeUrl = (url: string) => {
  const { apigee } = getEnvironmentConfigs();
  const baseUrl = apigee.url || window.location.origin;

  return getUrl(url, baseUrl);
};

export const getNewCustomerInitUrl = (url: string) => {
  const { newCustomerInit } = getEnvironmentConfigs();
  const baseUrl = newCustomerInit.url || window.location.origin;

  return getUrl(url, baseUrl);
};

const getUrl = (url: string, baseURL: string) => {
  return new URL(url, baseURL);
};
