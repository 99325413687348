import { InfoOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

import { SsoLayout } from '../components/SsoLayout';

export const PostPasswordSetup = () => {
  return (
    <SsoLayout>
      <Typography variant="h2" gutterBottom data-test="header-text">
        New password setup
      </Typography>
      <Typography gutterBottom data-test="password-success-text">
        Password for the new SSO was set sucessfuly.
      </Typography>
      <Grid container marginTop={4} alignItems="center">
        <Grid xs={1} item>
          <InfoOutlined color="info" fontSize="medium" />
        </Grid>
        <Grid xs={11} item>
          <Typography gutterBottom data-test="password-info-text">
            If you didn't set password for current sso system, you need to set
            it up. There should be another email with set password url, please
            check your mail box.
          </Typography>
        </Grid>
      </Grid>
    </SsoLayout>
  );
};
