import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../components/AuthContext';
import { SsoLayout } from '../components/SsoLayout';
import {
  getOAuthRedirectURLInvalidQuery,
  hasForceLogin,
  isOAuthQueryValid,
  isWrongCredentialsMessage,
  setSearchParam,
} from '../utils';

import {
  FormValues,
  LoginForm,
  EMAIL_FIELD,
  PASSWORD_FIELD,
  validationSchema,
} from './LoginForm';

const initialValues: FormValues = {
  [EMAIL_FIELD]: '',
  [PASSWORD_FIELD]: '',
};

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, signInWithEmailAndPassword } = useAuth();

  const formMethods = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      try {
        await signInWithEmailAndPassword(
          values[EMAIL_FIELD],
          values[PASSWORD_FIELD],
        );
        navigate(
          `/consent${setSearchParam(location, { forceLogin: false })}${
            location.hash
          }`,
        );
      } catch (error) {
        // we display general error message for wrong password or email and others errors
        const message = isWrongCredentialsMessage(error.message)
          ? 'Invalid username or password'
          : 'Something was wrong with login process. Please try again later.';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [signInWithEmailAndPassword, navigate, location, enqueueSnackbar],
  );

  const onForgotPasswordHandler = useCallback(
    () => navigate(`/forgot-password${location.search}${location.hash}`),
    [location, navigate],
  );

  if (!isOAuthQueryValid(location)) {
    window.location.href = getOAuthRedirectURLInvalidQuery();
    return null;
  }

  if (currentUser && !hasForceLogin(location)) {
    return <Navigate to={`/consent${location.search}${location.hash}`} />;
  }

  return (
    <SsoLayout>
      <FormProvider {...formMethods}>
        <LoginForm
          onSubmitHandler={formMethods.handleSubmit(onSubmit)}
          onForgotPassword={onForgotPasswordHandler}
        />
      </FormProvider>
    </SsoLayout>
  );
};
