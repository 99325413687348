import { ReactNode } from 'react';
import { ErrorBoundary as ErrorBoundaryWrapper } from 'react-error-boundary';
import Rollbar from 'rollbar';

import { ErrorProps, GeneralError } from '@openx/components/core';

type ErrorBoundaryProps = {
  children: ReactNode;
  rollbar?: Rollbar;
  redirectTo?: string;
  errorProps?: ErrorProps;
};

function logError(rollbar: Rollbar | undefined) {
  return (error: Error, info?: { componentStack?: string | null }) => {
    /* eslint-disable no-console */
    console.error('ErrorBoundary', error, info?.componentStack);

    rollbar && rollbar.error(error);
  };
}

export function ErrorBoundary({
  children,
  rollbar,
  redirectTo,
  errorProps,
}: ErrorBoundaryProps): JSX.Element {
  const onClick = () => {
    window.location.href = redirectTo || '/';
  };

  return (
    <ErrorBoundaryWrapper
      fallbackRender={() => (
        <GeneralError
          onClick={onClick}
          data-test="error-refresh"
          {...errorProps}
        />
      )}
      onError={logError(rollbar)}
    >
      {children}
    </ErrorBoundaryWrapper>
  );
}
