import { Grid, Typography } from '@mui/material';
import { FormEventHandler, MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { object, string } from 'yup';

import { Button, FormContainer, RHFTextField } from '@openx/components/core';
import { Shape } from '@openx/types';

export const NEW_PASSWORD_FIELD = 'newPassword';
export interface FormValues {
  [NEW_PASSWORD_FIELD]: string;
}
export interface SetPasswordFormValues {
  newPassword: string;
}

export const validationSchema = object<Shape<FormValues>>().shape({
  [NEW_PASSWORD_FIELD]: string().required('Password is required'),
});

export interface SetPasswordFormProps {
  userEmail: string;
  onSubmitHandler: FormEventHandler<HTMLFormElement>;
  onCancel: MouseEventHandler<HTMLElement>;
}

export function SetPasswordForm({
  onSubmitHandler,
  onCancel,
  userEmail,
}: SetPasswordFormProps): JSX.Element {
  const {
    formState: { isDirty, isSubmitting, isValid },
  } = useFormContext();

  return (
    <>
      <Typography variant="h2" gutterBottom data-test="set-password-text">
        New Password
      </Typography>
      <Typography variant="body1" data-test="set-password-subtitle">
        Set password for: {<b>{userEmail}</b>}
      </Typography>
      <form onSubmit={onSubmitHandler} data-test="forgot-password-form">
        <FormContainer fullPage>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFTextField
                name={NEW_PASSWORD_FIELD}
                type="password"
                label="New password"
                placeholder="Enter new password"
                required
                fullWidth
                margin="dense"
                autoComplete="new-password"
                data-test="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                direction="row"
              >
                <Grid item>
                  <Button variant="text" onClick={onCancel} data-test="cancel">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting || !isDirty}
                    data-test="set-password"
                  >
                    Set password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      </form>
    </>
  );
}
