import packageInfo from '../package.json';

export const TIMEOUT_BEFORE_REDIRECT = 5000;

export const APP_VERSION = packageInfo.version;
export const ENV_CONFIGS_PATH = '/configs.json';

export const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-172639934-3';
export const HOTJAR_ID = 1968768;
export const HOTJAR_SV = 6;
export const ROLLBAR_ACCESS_TOKEN = 'b7a085a6be28475d85f0ccd67fa72402';
