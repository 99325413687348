import { Route, RouteProps, Routes } from 'react-router-dom';

import { PageNotFound } from '@openx/components/core';

import { TIMEOUT_BEFORE_REDIRECT } from 'config';
import { Consent } from 'modules/auth/consent';
import { ForgotPassword } from 'modules/auth/forgotPassword';
import { Login } from 'modules/auth/login';
import { PostPasswordSetup } from 'modules/auth/postPasswordSetup';
import { UserManagement } from 'modules/auth/userManagement';

import { getEnvironmentConfigs } from '../environmentConfigs';

const routes: RouteProps[] = [
  {
    Component: () => {
      setTimeout(() => {
        window.location.href = getEnvironmentConfigs().defaultRedirectURI;
      }, TIMEOUT_BEFORE_REDIRECT);
      return null;
    },
    path: '/',
  },
  {
    element: <Login />,
    path: '/login',
  },
  {
    element: <ForgotPassword />,
    path: '/forgot-password',
  },
  {
    element: <UserManagement />,
    path: '/user-management',
  },
  {
    element: <Consent />,
    path: '/consent',
  },
  {
    element: <PostPasswordSetup />,
    path: '/password-setup',
  },
  {
    element: <PageNotFound />,
    path: '*',
  },
];

const RoutesComponent = () => (
  <Routes>
    {routes.map((route, i) => (
      <Route key={i} {...route} />
    ))}
  </Routes>
);

export default RoutesComponent;
