import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing()};
`;

export interface CloseNotificationButtonProps {
  action: () => void;
}

export function CloseNotificationButton({ action }: CloseNotificationButtonProps): JSX.Element {
  return (
    <StyledIconButton key="close" aria-label="Close" color="inherit" onClick={action} size="large">
      <CloseIcon />
    </StyledIconButton>
  );
}
