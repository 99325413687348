import {
  FormHelperTextProps as MuiFormHelperTextProps,
  TextFieldProps,
  TextField as MuiTextField,
} from '@mui/material';
import { Control, FieldPath, useController, FieldValues } from 'react-hook-form';

export type MuiTextFieldProps = TextFieldProps & { 'data-test'?: string };

export type RHFTextFieldProps<T extends FieldValues> = Omit<MuiTextFieldProps, 'name | FormHelperTextProps'> & {
  name: FieldPath<T>;
  control?: Control<T>;
  rules?: Record<string, unknown>;
  // Fix missing typo in mui lib
  // Drop whole FormHelperTextProps after fix in mui
  FormHelperTextProps?: MuiFormHelperTextProps<React.ElementType, { component: React.ElementType }>;
};

export const RHFTextField = <T extends Record<string, unknown> = Record<string, string>>({
  name,
  control,
  FormHelperTextProps,
  rules,
  ...rest
}: RHFTextFieldProps<T>): JSX.Element => {
  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
    rules: rules,
  });

  const defaultProps: MuiTextFieldProps = {
    InputLabelProps: { disableAnimation: true, shrink: true },
    InputProps: { disableUnderline: true },
    'data-test': rest['data-test'],
    variant: 'standard',
  };

  return (
    <MuiTextField
      {...defaultProps}
      {...field}
      {...rest}
      FormHelperTextProps={FormHelperTextProps}
      disabled={isSubmitting || rest.disabled}
      error={invalid || rest.error}
      helperText={error?.message || rest.helperText}
    />
  );
};
