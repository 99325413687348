import { ComponentType, HTMLAttributes } from 'react';

import { ListboxComponent } from './AutoCompleteVirtualize';
import { RHFAutoComplete } from './RHFAutoComplete';
import { RHFAutocompleteProps } from './types';

export const RHFAutoCompleteVirtualize = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  ...rest
}: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  return (
    <RHFAutoComplete
      name={name}
      disableListWrap
      ListboxComponent={ListboxComponent as ComponentType<HTMLAttributes<HTMLElement>>}
      {...rest}
    />
  );
};
