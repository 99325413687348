import { styled, css } from '@mui/material/styles';
import { useMemo } from 'react';

import { prepareHighlightedPhrase, HighlightPhraseProps } from '@openx/utils';

const StyledSpan = styled('span')<{ isHighlighted: boolean }>`
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      background-color: ${theme.palette.corn};
    `}
`;

export interface HighlightedPhraseProps {
  children: string;
  searchPhrase?: HighlightPhraseProps;
}

export function HighlightedPhrase(props: HighlightedPhraseProps): JSX.Element {
  const { children, searchPhrase } = props;
  const content = children || '';

  const highlightedChunks = useMemo(() => prepareHighlightedPhrase(content, searchPhrase), [content, searchPhrase]);

  return (
    <span>
      {highlightedChunks.map((chunk, index) => (
        <StyledSpan
          isHighlighted={chunk.highlighted}
          data-test={chunk.highlighted ? 'highlighted' : undefined}
          key={index}
        >
          {chunk.content}
        </StyledSpan>
      ))}
    </span>
  );
}
