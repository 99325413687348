import { getEnvironmentConfigs } from 'environmentConfigs';
import { User } from 'firebase/auth';

import type { Session } from '../types';

import { getApigeeUrl } from './getUrl';
import { FetchSessionInfoError } from './types';

type FetchSessionInfoParams = {
  currentUser: User | null;
  sessionId: string;
};
export type FetchSessionInfoResponse = {
  error: FetchSessionInfoError | null;
  session: Session | null;
};

export const fetchSessionInfoResponse = async ({
  currentUser,
  sessionId,
}: FetchSessionInfoParams): Promise<FetchSessionInfoResponse> => {
  if (!currentUser) {
    return {
      error: {
        message: 'Unauthenticated',
        name: 'Error',
        type: 'authentication',
      },
      session: null,
    };
  }

  const idToken = await currentUser.getIdToken();
  const { apigee } = getEnvironmentConfigs();
  const url = getApigeeUrl(apigee.sessionInfoPath);

  if (!sessionId) {
    return {
      error: {
        message: 'No session id',
        name: 'Error',
        type: 'unknown',
      },
      session: null,
    };
  }

  url.searchParams.set('session_id', sessionId);
  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  const { status, statusText } = response;

  if (!response.ok) {
    if (status === 404) {
      return {
        error: {
          message: 'Authorization session not found',
          name: 'Error',
          type: 'session_not_found',
        },
        session: null,
      };
    }
    if (status === 403) {
      return {
        error: {
          message: 'Authorization error',
          name: 'Error',
          type: 'authorization',
        },
        session: null,
      };
    }
    return {
      error: {
        message: `Unknown error ${status}:${statusText}`,
        name: 'Error',
        type: 'unknown',
      },
      session: null,
    };
  }

  try {
    const session = await response.json();
    return {
      error: null,
      session,
    };
  } catch (err) {
    return {
      error: {
        message: err.message,
        name: 'Error',
        type: 'unknown',
      },
      session: null,
    };
  }
};
