import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, InfoOutlined } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import {
  SnackbarProvider as SnackbarProviderNotistack,
  SnackbarProviderProps as SnackbarProviderNotistackProps,
} from 'notistack';
import { forwardRef } from 'react';

import { StyledSnackbarWrapperDiv } from './SnackbarWrapper';

const WarningOutlined = () => (
  <SvgIcon>
    <path d="M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
  </SvgIcon>
);

export const SnackbarProvider = forwardRef<SnackbarProviderNotistack, SnackbarProviderNotistackProps>(
  ({ children, anchorOrigin = { horizontal: 'center', vertical: 'bottom' }, ...restProps }, ref): JSX.Element => {
    return (
      <StyledSnackbarWrapperDiv>
        <SnackbarProviderNotistack
          ref={ref}
          data-test="snackbar"
          autoHideDuration={15000}
          anchorOrigin={anchorOrigin}
          preventDuplicate
          iconVariant={{
            error: <ErrorOutlineOutlined />,
            info: <InfoOutlined />,
            success: <CheckCircleOutlineOutlined />,
            warning: <WarningOutlined />,
          }}
          {...restProps}
        >
          {children}
        </SnackbarProviderNotistack>
      </StyledSnackbarWrapperDiv>
    );
  },
);
