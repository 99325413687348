import axios from 'axios';
import { getEnvironmentConfigs } from 'environmentConfigs';
import {
  Auth,
  User,
  UserCredential,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  verifyPasswordResetCode as fbVerifyPasswordResetCode,
  confirmPasswordReset as fbConfirmPasswordReset,
  signOut as fbSignOut,
} from 'firebase/auth';
import React, { createContext, useContext, useState, useEffect } from 'react';

interface AuthContext {
  currentUser: User | null;
  signInWithEmailAndPassword: (
    login: string,
    password: string,
  ) => Promise<UserCredential>;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  verifyPasswordResetCode: (code: string) => Promise<string>;
  confirmPasswordReset: (oobCode: string, newPassword: string) => Promise<void>;
}

const authContext = createContext<AuthContext>({
  confirmPasswordReset: () => {
    throw new Error('Not implemented.');
  },
  currentUser: null,
  sendPasswordResetEmail: () => {
    throw new Error('Not implemented.');
  },
  signInWithEmailAndPassword: () => {
    throw new Error('Not implemented.');
  },
  signOut: () => {
    throw new Error('Not implemented.');
  },
  verifyPasswordResetCode: () => {
    throw new Error('Not implemented.');
  },
});

export const useAuth = () => useContext(authContext);

export interface AuthProviderProps {
  children: React.ReactNode;
  auth: Auth;
}

export function AuthProvider({ children, auth }: AuthProviderProps) {
  const { apigee } = getEnvironmentConfigs();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);
  const triggerPasswordResetEmail = async (email: string) => {
    const params = new URLSearchParams({ email });
    await axios.post(`${apigee.url}${apigee.resetPasswordPath}`, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  };

  const value: AuthContext = {
    confirmPasswordReset: (oobCode, newPassword) =>
      fbConfirmPasswordReset(auth, oobCode, newPassword),
    currentUser,
    sendPasswordResetEmail: email => triggerPasswordResetEmail(email),
    signInWithEmailAndPassword: (email, password) =>
      fbSignInWithEmailAndPassword(auth, email, password),
    signOut: () => fbSignOut(auth),
    verifyPasswordResetCode: code => fbVerifyPasswordResetCode(auth, code),
  };

  return (
    <authContext.Provider value={value}>
      {!loading && children}
    </authContext.Provider>
  );
}
